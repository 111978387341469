<template>
  <div class="w-full px-2">
    <div class="flex flex-wrap -mx-2">
      <div class="w-full xs:w-full bg sm:w-full px-2 mb-2">
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-primary text-2xl font-bold">
              {{ competition.name }}
            </span>
          </div>
        </div>
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-grey-light text-xl">
              Overview
            </span>
          </div>
        </div>
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <p class="text-grey-light text-lg" v-html="competition.long_description">
            </p>
          </div>
        </div>
        <!--        <div class="vx-row bg-white w-full mt-5">
                  <div class="w-full p-2">
                    <span class="text-primary text-2xl">
                      Regular Category characteristics:
                    </span>
                  </div>
                </div>

                <div class="vx-row bg-white w-full">
                  <div class="w-full p-2">
                    <span class="text-black text-2xl">
                      Four age groups:
                    </span>
                    <span class="text-grey-light text-lg">
                      <ul style="list-style:disc" class="ml-8 mt-2">
                        <li>WeDo (up to 10y), see more information below.</li>
                        <li>
                          Elementary (up to 12y).
                        </li>
                        <li>
                          Junior (13-15y).
                        </li>
                        <li>
                          Senior (16-19y).
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
                <div class="vx-row bg-white w-full mt-5">
                  <div class="w-full p-2">
                    <span class="text-primary text-2xl">
                      This is Regular Category
                    </span>
                  </div>
                </div>
                <div class="vx-row bg-white w-full">
                  <div class="w-full p-2">
                    <span class="text-grey-light text-lg">
                      dedicated to science, technology and education.
                    </span>
                  </div>
                </div>-->
      </div>

      <div class="vx-row bg-white w-full mt-2">
        <div class="w-full ">
          <!-- swiper -->
          <!--          <swiper
                      :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
                      &lt;!&ndash; v-for="(item, index) in List" :key="index" &ndash;&gt;
                      <swiper-slide
                        v-for="(slideContent, index) in competition.photos" :key="index" :virtualIndex="index">
                        <img class="responsive" :src="slideContent.image" alt="banner"/>
                      </swiper-slide>
                      <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>-->
          <div class="vx-row">
            <div class="w-full md:w-1/2 lg:w-1/3" v-for="details in competition.details" :key="details.id">
              <div class="game-guide-element">
                <h3>{{details.title}}</h3>
                <div class="game-guide-img">
                  <img :src="details.image" alt="">
                </div>
                <p class="game-guide-link">
                  <a :href="details.link" target="_blank">Click-Here</a>
                  <span>For More</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import {swiper, swiperSlide} from "vue-awesome-swiper";

export default {
  data() {
    return {
      List: [
        require("@/assets/images/re/66.jpeg"),
        require("@/assets/images/re/62.jpeg"),
        require("@/assets/images/re/61.png"),
        require("@/assets/images/re/60.png"),
        require("@/assets/images/re/1.png"),
        require("@/assets/images/re/63.jpeg"),
        require("@/assets/images/re/64.jpeg"),
        require("@/assets/images/re/65.png"),

      ],
      swiperOption: {
        slidesPerView: 3,
        slidesPerColumn: 1,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        },

      },
      competition: null
    };
  },
  components: {
    swiper,
    swiperSlide
  },
  mounted() {
    this.$API.competitions.getCompetitions()
      .then(response => {
        this.competition = response.find(comp => comp.id == this.$route.params.id)
      })
  }
};
</script>

<style lang="scss" scoped>
.swiper-wrapper {
  height: initial;
}

.swiper-slide {
  overflow: hidden;
}

.swiper-container {
  padding-bottom: 50px;
}
.carousel-example .swiper-container .swiper-slide {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  background-color: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 300px;
}

.responsive {
  width: 100% !important;
  height: 200px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

</style>
